// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-article-strapi-article-slug-js": () => import("./../../../src/pages/article/{strapiArticle.Slug}.js" /* webpackChunkName: "component---src-pages-article-strapi-article-slug-js" */),
  "component---src-pages-category-strapi-category-slug-js": () => import("./../../../src/pages/category/{strapiCategory.Slug}.js" /* webpackChunkName: "component---src-pages-category-strapi-category-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-latest-articles-js": () => import("./../../../src/templates/latest-articles.js" /* webpackChunkName: "component---src-templates-latest-articles-js" */)
}

